<template>
<div v-if="list.length" class="card-list">
	<div v-for="book in list" :key="book">
		<router-link :to="book.url" class="image">
			<img :src="book.cover_url" class="card-list-image"/>
		</router-link>
		<div class="info">
			<h4><router-link :to="book.adv_url">{{ book.title }}</router-link></h4>
			<p class="flex-g">
				<AdvChip v-if="book.adv" :type="book.adv.type"/>
				<UserChip v-if="book.user" :user="book.user"/>
			</p>
			<p v-if="book.adv">{{ book.adv.message }}</p>
			<dl class="infobox">
				<dt v-if="book.authors" class="mdi mdi-fountain-pen-tip">Autore</dt>
				<dd v-if="book.authors">{{ book.authors }}</dd>
				<dt v-if="book.city" class="mdi mdi-map-marker">Luogo</dt>
				<dd v-if="book.city">{{ book.city }}</dd>
				<dt v-if="book.adv && (book.adv.type == 'sell' || book.adv.type == 'fixsell')" class="mdi mdi-currency-eur">Prezzo</dt>
				<dd v-if="book.adv && (book.adv.type == 'sell' || book.adv.type == 'fixsell')">{{ book.adv.price }}</dd>
			</dl>
			<p v-if="book.cta" class="flex-b">
				<router-link :to="book.cta.url" class="submit mdi" :class="book.cta.icon">
					{{ book.cta.label }}
				</router-link>
			</p>
			<p v-if="book.cta2" class="flex-b">
				<a class="submit mdi" :class="book.cta2.icon" @click="$emit('cta', book.cta2)">
					{{ book.cta2.label }}
				</a>
			</p>
		</div>
	</div>
</div>
</template>

<style lang="scss">
.card-list {

	> div {
		background: white;
		box-shadow: var(--shadow-1);
		margin: 1rem auto;
		overflow: auto;
		display: flex;
		> a {
			background: var(--color-gray-alt);
		}
	}

	&-image {
		width: 8rem;
		aspect-ratio: 2 / 3;
		display: block;
		object-fit: cover;
	}

	.info {
		padding: 0 1rem;
		flex: auto;
	}
}
</style>


<script>

import AdvChip from "@/components/AdvChip.vue";
import UserChip from "@/components/UserChip.vue";

export default {
	props: ["list"],
	components: { AdvChip, UserChip },
	emits: ["cta"],
}
</script>
