<template>
<header>
	<div class="primary">
		<router-link v-if="Store.main.back" :to="{ path: Store.main.back }" class="mdi mdi-arrow-left"></router-link>
		<a v-else @click="Store.showMenu()" class="mdi mdi-menu"></a>
		<span>{{ Store.main.title }}</span>
		<a v-for="i in Store.main.actions" :key="i" :title="i.title" :class="i.class" @click="i.callback()"></a>
	</div>
	<div v-if="Store.main.tabs" class="secondary">
		<router-link v-for="tab in Store.main.tabs" :key="tab" :to="{ path: tab.path }">{{ tab.label }}</router-link>
	</div>
</header>
</template>

<style lang="scss">
header {
	background: var(--color-primary);
	color: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	line-height: 3rem;
	box-sizing: border-box;
	box-shadow: var(--shadow-2);

	div {
		padding: 0 1rem;
		&.primary {
			font-size: 1.2rem;
			display: flex;
			span {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				flex: auto;
			}
			a {
				color: white;
				text-align: center;
				width: 3rem;
				height: 3rem;
				line-height: 3rem;
				flex: 0 0 3rem;
				border-radius: 1.5rem;
				display: inline-block;
				&:hover {
					background: var(--color-vibrant);
					color: white;
				}
				&::before {
					padding: 0 !important;
				}
			}
		}
		&.secondary {
			background: var(--color-secondary);
			display: flex;
			text-transform: uppercase;
			font-size: .8rem;
			height: 3rem;
			justify-content: center;

			a {
				box-sizing: border-box;
				color: white;
				border-style: solid;
				border-width: 0 0 3px 0;
				border-color: transparent;
				padding: 0 1rem;
				&:hover {
					background: var(--color-vibrant);
				}
				&.router-link-exact-active {
					border-color: white;
				}
			}
		}
	}

}
</style>

<script>

import Store from "@/store.js";

export default {
	data: () => ({
		Store,
	}),
}

</script>
