<template>
<div class="overlay share" v-if="Store.share" @click.self="Store.unsetShare()">
	<div class="card">
		<h2>Condividi</h2>
		<p v-for="i in Store.share" :key="i.url" class="text-center">
			<a :href="i.url" :class="i.class" :style="{ backgroundColor: i.color }" target="_blank">{{ i.name }}</a>
		</p>
	</div>
</div>
</template>

<style lang="scss">
.share > div {

	a {
		display: inline-block;
		width: 12rem;
		padding: 1rem;
		color: white;
		font-weight: bold;
		box-shadow: var(--shadow-1);
		border-radius: 3px;
		&:hover {
			color: white;
			opacity: .8;
			box-shadow: var(--shadow-2);
		}
	}

	span {
		flex: auto;
	}
}
</style>

<script>

import Store from "@/store.js"

export default {
	data: () => ({
		Store,
	}),
}

</script>
