import { reactive } from 'vue'

let user = localStorage.getItem("user");
if (user) user = JSON.parse(user);

let options = localStorage.getItem("options");
options = (options ? JSON.parse(options) : {});

class User {
	constructor(d) {
		this.id_user = d.id_user;
		this.name = d.name;
		this.hash = d.hash;
		this.feedback = d.feedback;
		this.bio = (d.bio || null);

		this.avatar_url = (this.hash ? 'https://www.gravatar.com/avatar/'+this.hash+'?s=48&d=identicon' : "/img/mistery-man.jpg");
		this.avatar160_url = (this.hash ? 'https://www.gravatar.com/avatar/'+this.hash+'?s=160&d=identicon' : "/img/mistery-man.jpg");
		this.url = "/profile/"+this.id_user;
	}
}

class Book {
	constructor(d) {
		this.id_book = d.id_book;
		this.id_library = d.id_library;
		this.title = d.title;
		this.authors = d.authors;
		this.city = d.city;
		this.isbn = d.isbn;

		this.data = d.data;
		if (this.data) {
			if (this.data.authors) this.data.authors_str = this.data.authors.map(a => a.name).join(" ");
			if (this.data.publishers) this.data.publishers_str = this.data.publishers.map(a => a.name).join(" ");
		}

		let adv = null;
		if (d.advert) adv = d.advert;
		else if (d.id_advert) adv = d;

		if (adv) {
			this.adv = {
				id_user: adv.id_user,
				id_advert: adv.id_advert,
				status: adv.status,
				price: adv.price,
				price_q: adv.price_q,
				message: adv.message,
				message_list: null,
				type: adv.type,
				datetime: Store.dateFormat(adv.datetime),
				queue: adv.queue,
				queued: !!adv.queued,
				city: adv.city,
				position_x: adv.position_x,
				position_y: adv.position_y,
			};

			if (adv.message_list) this.setMessageList(adv.message_list);
		} else {
			this.adv = null;
		}

		if (d.id_user) {
			this.user = new User({
				id_user: d.id_user,
				name: d.name,
				hash: d.hash,
				feedback: d.feedback,
			});
		} else {
			this.user = null;
		}

		this.cover_url = (d.cover ? process.env.VUE_APP_COVERS+'/covers/'+this.id_library+'.jpg' : "/img/white-book.jpg");
		this.url = "/book/"+this.id_book;
		this.adv_url = (this.adv ? "/book/"+this.id_book+"/advert/"+this.adv.id_advert : this.url);
		this.remove_url = "/book/"+this.id_book+"/remove";
	}

	setAdvQueue(queue, queued, price_q = null) {
		this.adv.queue = queue;
		this.adv.queued = queued;
		this.adv.price_q = price_q;
	}

	setMessageList(list) {
		this.adv.message_list = list.map(a => ({
			id_advert_message: a.id_advert_message,
			datetime: Store.dateFormat(a.datetime),
			message: a.message,
			status: a.status,
			user: new User({
				id_user: a.id_user,
				name: a.name,
				hash: a.hash,
				feedback: a.feedback,
			}),
			id_advert_message_parent: a.id_advert_message_parent,
			message_parent: a.message_parent,
			datetime_parent: Store.dateFormat(a.datetime_parent),
			user_parent: (a.id_user_parent ? new User({
				id_user: a.id_user_parent,
				name: a.name_parent,
				hash: a.hash_parent,
				feedback: a.feedback_parent,
			}) : null),
		}));
	}
}

var Store = reactive({
	endpoint: process.env.VUE_APP_ENDPOINT,
	name: "Trallallibro",

	menu: false,
	main: { title: "Trallallibro", back: null, tabs: null },
	notices: 0,
	messages: reactive([]),
	notfound: false,

	user,
	cachedBook: null,
	cachedUser: null,
	options,
	share: null,
	modalDialog: null,

	events: {
		create: {
			icon: "mdi-flare",
			label: "Inserito",
			descr: "Inserimento iniziale",
			color: "black",
		},
		donate: {
			icon: 'mdi-gift',
			label: "Regalo",
			descr: "Ricevuto in regalo",
			color: "#99C02A",
		},
		swap: {
			icon: 'mdi-swap-horizontal',
			label: "Scambio",
			descr: "Ricevuto come scambio",
			color: "#1EAADE",
		},
		lend: {
			icon: 'mdi-alarm',
			label: "Prestito",
			descr: "Ricevuto in prestito",
			color: "#F8D02D",
		},
		share: {
			icon: 'mdi-share-variant',
			label: "Condivisione",
			descr: "",
			color: "#009888",
		},
		fixsell: {
			icon: 'mdi-shopping',
			label: "Vendita",
			descr: "Acquistato",
			color: "#CF321B",
		},
		sell: {
			icon: 'mdi-shopping',
			label: "Vendita",
			descr: "Acquistato",
			color: "#cf4913",
		},
	},

	showMenu() {
		this.menu = true;
	},

	hideMenu() {
		this.menu = false;
	},

	async fetch(action, params = {}) {
		return await this._fetch(action, params);
	},

	async rPut(action, params = {}) {
		return await this._fetch(action, params, { method: "PUT" });
	},

	async rDelete(action, params = {}) {
		return await this._fetch(action, params, { method: "DELETE" });
	},

	async _fetch(action, params = {}, options = {}) {

		let query = [];
		for (let i in params) query.push(i+"="+encodeURIComponent(params[i]));

		let opt = {
			method: (options.method || "GET"),
		};
		if (this.user) opt.headers = { Authorization: 'Bearer '+this.user.token };

		let res = await fetch(this.endpoint+action+"?"+query.join("&"), opt);

		let data = await res.json();

		if (data.error) {
			if (data.action === "notauthorized") {
				Store.unsetUser();
				throw new Error();
			} else if (data.action === "notfound") {
				this.notfound = true;
				throw new Error();
			} else {
				this.pushMessage(data.errorMessage, "error");
				throw new Error(data.errorMessage);
			}
		}

		return data.response;
	},

	async submit(action, f, params = {}) {

		let query = [];
		for (let i in params) query.push(i+"="+encodeURIComponent(params[i]));

		let opt = {
			method: "POST",
			body: f,
		};
		if (this.user) opt.headers = { Authorization: 'Bearer '+this.user.token };

		let res = await fetch(this.endpoint+action+"?"+query.join("&"), opt);

		let data = await res.json();

		if (data.error) {
			this.pushMessage(data.errorMessage, "error");
			throw new Error(data.errorMessage);
		}

		return data.response;

	},

	async fetchBook(id) {
		return await this.fetch("/book/"+id);
	},

	setUser(user = null) {
		if (user) this.user = user;
		localStorage.setItem("user", JSON.stringify(this.user));
	},

	unsetUser() {
		this.user = null;
		localStorage.removeItem("user");
	},

	setView(title, opts = {}) {
		document.title = this.main.title = title;
		this.main.back = (opts.back !== undefined ? opts.back : null);
		this.main.tabs = (opts.tabs !== undefined ? opts.tabs : null);
		this.main.actions = (opts.actions !== undefined ? opts.actions : null);
		if (opts.tabs) document.body.classList.add("doubleMenu"); else document.body.classList.remove("doubleMenu");

		if (window.gaEvent) {
			this.trackEvent('sceen_view', {
				page_title: title,
				page_location: 'https://www.trallallibro.com'+location.pathname,
			});
		} else {
			setTimeout(() => {
				this.trackEvent('sceen_view', {
					page_title: title,
					page_location: 'https://www.trallallibro.com'+location.pathname,
				});
			}, 1500);
		}
	},

	trackEvent(type, params) {
		if (window.gaEvent) window.gaEvent(type, params);
	},

	pushMessage(text, type = "success") {
		let ts = new Date;
		ts.setSeconds(ts.getSeconds() + 5);
		this.messages.push({ text, type, ts });
	},

	removeMessage(i) {
		this.messages.splice(i, 1);
	},

	cron() {
		let ts = new Date();
		while (this.messages.length && this.messages[0].ts < ts) this.messages.shift();
	},

	setOption(k, v) {
		this.options[k] = v;
		localStorage.setItem("options", JSON.stringify(this.options));
	},

	userFactory(d) {
		return new User(d);
	},

	bookFactory(d, mixin = null) {
		let b = new Book(d);
		if (mixin) Object.assign(b, mixin);
		return b;
	},

	dateFormat(d) {
		if (!d) return null;
		let m = d.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
		return m[3]+"/"+m[2]+"/"+m[1]+" "+m[4]+":"+m[5];
	},

	setShare(text) {
		this.share = [
			{
				name: "Facebook",
				url: "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(location.href)+"&title="+encodeURIComponent(text),
				class: "mdi mdi-facebook",
				color: "#1877F2",
			}, {
				name: "Twitter",
				url: "https://twitter.com/intent/tweet?source=webclient&original_referer="+encodeURIComponent(location.href)+"&text="+encodeURIComponent(text),
				class: "mdi mdi-twitter",
				color: "#1DA1F2",
			}
		];
	},

	unsetShare() {
		this.share = null;
	},

	showModalDialog(message, title = null) {
		this.modalDialog = { message, title, mode: "alert" };
	},

	showConfirmModalDialog(message, callback, title = null) {
		this.modalDialog = { message, title, callback, mode: "confirm" };
	},

	confirmModalDialog() {
		this.modalDialog.callback();
	},

	hideModalDialog() {
		this.modalDialog = null;
	},

	formValidation(form) {
		form.removeAttribute("novalidate");
		form.classList.add("validate");
	},
})

setInterval(() => Store.cron(), 1000);

export default Store;
