<template>
<div class="spinner">
	<img src="/img/spinner.svg"/>
</div>
</template>

<style lang="scss">
.spinner {
	img {
		width: 20rem;
		max-width: 75%;
		display: block;
		margin: 6rem auto 0;
	}
}
</style>
