<template>
<aside v-if="Store.modalDialog" class="overlay" @click.self="Store.hideModalDialog()">
	<div class="card modal-dialog">
		<h2 v-if="Store.modalDialog.title">{{ Store.modalDialog.title }}</h2>
		<p class="text-center">
			{{ Store.modalDialog.message }}
		</p>
		<p class="flex-c" v-if="Store.modalDialog.mode=='alert'">
			<a class="submit mdi mdi-close" @click="Store.hideModalDialog">Chiudi</a>
		</p>
		<p class="flex-c" v-if="Store.modalDialog.mode=='confirm'">
			<a class="submit mdi mdi-check" @click="Store.confirmModalDialog">Conferma</a>
			<a class="button mdi mdi-close" @click="Store.hideModalDialog">Annulla</a>
		</p>
	</div>
</aside>
</template>

<script>

import Store from "@/store.js"

export default {
	data: () => ({
		Store,
	}),
}
</script>
