<template>
<span v-if="type" class="AdvChip" :style="{ backgroundColor: Store.events[type].color }">
	<i class="mdi" :class="Store.events[type].icon"></i>
	<span>{{ Store.events[type].label }}</span>
</span>
</template>

<style lang="scss">

.AdvChip {
	color: white;
	line-height: 2rem;
	display: inline-block;
	border-radius: 1rem;
	overflow: hidden;
	box-shadow: var(--shadow-1);

	span {
		padding: 0 1rem 0 .5rem;
	}

	i {
		width: 2rem;
		height: 2rem;
		display: inline-block;
		text-align: center;
		background: rgba(0, 0, 0, .25);
		border-radius: 1rem;
	}
}

</style>

<script>

import Store from "@/store.js"

export default {
	data: () => ({
		Store,
	}),

	props: ["type"],
}
</script>
