<template>
	<MainBar/>
	<router-view/>
	<Messages/>
	<NavBottom/>
	<NavDrawer/>
	<Share/>
	<ModalDialog/>
</template>

<style lang="scss">

:root {
	--color-primary: #00438a;
	--color-secondary: #0057ae;
	--color-vibrant: #0066ff;
	--color-gray: #999;
	--color-gray-alt: #ddd;
	--shadow-1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	--shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	--shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

* {
	transition: color .3s, background-color .3s, box-shadow .3s, border .3s;
	scrollbar-width: thin;
	scrollbar-color: var(--color-primary) transparent;
	&:focus {
		outline: none;
	}
}

@import '~@mdi/font/css/materialdesignicons.css';

@font-face {
	font-family: ReenieBeanie;
	src: url(/public/fonts/ReenieBeanie-Regular.ttf);
}

@font-face {
	font-family: ProximaNova;
	src: url(/public/fonts/proxima_nova.ttf);
}

html,
body {
	margin: 0;
	padding: 0;
	overflow: auto;
	background: #eee;
	color: #333;
}

html {
	font: 16px ProximaNova;
}

main#app {
	overflow: auto;
	padding: 4rem 0;

	> section {
		max-width: 46rem;
		margin: auto;
	}
}

body.doubleMenu #app {
	padding-top: 7rem;
}

h1,
h2,
h3,
h4,
h6,
p {
	margin: 1rem 0;
}

h1 {
	font-size: 2.6rem;
	font-family: ReenieBeanie;
}

h2 {
	font-size: 1.4rem;
}

h3 {
	font-size: 1.2rem;
}

hr {
	margin: 1rem 0;
	border-color: var(--color-gray-alt);
	border-style: solid;
	border-width: 1px 0 0 0;
}

a {
	text-decoration: none;
	color: var(--color-primary);
	cursor: pointer;
	&:hover {
		color: var(--color-vibrant);
	}
	&.mdi::before {
		padding-right: .25em;
	}
}

.card {
	background: white;
	box-shadow: var(--shadow-1);
	margin: 1rem auto;
	padding: 0 1rem;
	overflow: auto;
}

.text {
	&-left {
		text-align: left;
	}

	&-right {
		text-align: right;
	}

	&-center {
		text-align: center;
	}

	&-danger {
		color: red !important;
	}
}

.row {
	label {
		display: flex;
		flex-direction: column;
		*:first-child {
			order: 2;
		}
	}

	span {
		font-size: .8rem;
	}

	input[type=text],
	input[type=email],
	input[type=password],
	input[type=search],
	input[type=number],
	textarea {
		border: 1px solid var(--color-gray-alt);
		border-width: 0 0 1px 0;
		font-family: inherit;
		font-size: inherit;
		padding: .5rem 0;
		margin-bottom: 1rem;

		&:read-only {
			background: var(--color-gray-alt);
		}


		&:focus,
		&:hover {
			border-color: var(--color-vibrant);
			+ span {
				color: var(--color-vibrant);
			}
		}
	}
}

form {
	margin: 1rem auto;
}

form.validate {
	input[type=text],
	input[type=email],
	input[type=password],
	input[type=search],
	input[type=number],
	textarea {
		&:invalid {
			border-color: red;
			+ span {
				color: red;
			}
		}
	}

	input[type=checkbox] {
		&:invalid {
			border-color: red;
		}
	}

}

button[type=button],
a.button,
button[type=submit],
a.submit {
	cursor: pointer;
	padding: 0 1rem;
	display: inline-block;
	border: none;
	box-shadow: var(--shadow-1);
	font-size: inherit;
	font-weight: bold;
	border-radius: 2px;
	min-width: 6rem;
	text-align: center;
	line-height: 2rem;
	height: 2rem;

	&:hover {
		box-shadow: var(--shadow-2);
	}

	&.mdi::before {
		padding-right: .25em;
	}
}

button[type=button],
a.button {
	background: white;
	color: #333;

	&:hover {
		background: var(--color-gray-alt);
	}
}

button[type=submit],
a.submit {
	background: var(--color-primary);
	color: white;
	font-weight: bold;
	min-width: 12rem;

	&:hover {
		background: var(--color-vibrant);
	}
}


.actions a {
	text-transform: uppercase;
	display: block;
	padding: 1rem .5rem;
	color: var(--color-primary);
	border-top: 1px solid var(--color-gray-alt);
	&:hover {
		color: var(--color-vibrant);
	}
	&.danger:hover {
		color: red;
	}
}

.flex-h {
	display: flex;
	.greedy {
		flex: auto;
	}
	h3 {
		margin: 0;
	}
}

.flex-g {
	display: flex;
	gap: 1rem;
	align-items: center;
	.greedy {
		flex: auto;
	}

	@media (max-width: 640px) {
		flex-direction: column;
		align-items: start;
	}

}

.flex-b {
	display: flex;
	gap: 1rem;
	justify-content: flex-end;
	.greedy {
		flex: auto;
	}
	@media (max-width: 640px) {
		flex-direction: column-reverse;
	}
}

.flex-c {
	display: flex;
	gap: 1rem;
	justify-content: center;
}

/* Riga composta da checkbox e testo */
.flex-l {
	display: flex;
	gap: .5rem;
	align-items: flex-start;
}

.floating-button {
	position: fixed;
	bottom: 5rem;
	right: 1rem;
	z-index: 1;
	background: var(--color-primary);
	color: white;
	width: 4rem;
	height: 4rem;
	border-radius: 2rem;
	line-height: 4rem;
	text-align: center;
	box-shadow: var(--shadow-2);
	&:hover {
		color: white;
		box-shadow: var(--shadow-3);
		background: var(--color-vibrant);
	}
}

.mdi-big {
	font-size: 5em;
}

.main-action {
	position: fixed;
	top: 0;
	right: 1rem;
	a {
		color: white;
		font-size: 1.2rem;
		text-align: center;
		width: 3rem;
		height: 3rem;
		display: block;
		line-height: 3rem;
		border-radius: 3px;
		&:hover {
			background: var(--color-vibrant);
		}
		&::before {
			padding: 0;
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;

	> div {
		z-index: 11;
		max-width: 40rem;
		width: calc(100% - 2rem);
		box-sizing: border-box;
	}

	&::after {
		content: "";
		background: rgba(0, 0, 0, .5);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.big-cover img {
	display: block;
	margin: auto;
	max-height: 24rem;
	border-radius: 3px;
	box-shadow: var(--shadow-1);
}

img.small-cover {
	float: right;
	margin: 1rem 0 1rem 1rem;
	box-shadow: var(--shadow-1);
	border-radius: 3px;
	max-width: 12rem;
	max-height: 18rem;
}

.text-notice {
	text-align: center;
	font-style: italic;
}

.compact-list {
	margin: 1rem 0;
	display: flex;

	div {
		flex: auto;
		padding-bottom: 1rem;
		border-bottom: 1px solid var(--color-gray-alt);
	}

	> i {
		flex: 0 0 4rem;
		text-align: center;
		font-size: 2rem;
		opacity: .5;
	}

	&-new {
		a {
			font-weight: bold;
		}
		> i {
			opacity: 1;
		}
	}

	&:last-child {
		div {
			border: none;
			padding-bottom: 0;
		}
	}


	time {
		display: block;
		font-size: .8rem;
	}

}

.big-avatar {
	display: block;
	width: 16rem;
	height: 16rem;
	float: right;
	margin: 1rem 0 1rem 1rem;
}

.quote {
	border-left: 3px solid var(--color-primary);
	padding-left: 1rem;
	overflow: auto;
	margin: 1rem 0;
}

.infobox {
	display: grid;
	grid-template-columns: 8rem auto;
	margin: 1rem 0;
	gap: .5rem 1rem;
	dt.mdi::before {
		margin-right: .25rem;
	}
	dd {
		margin: 0;
	}
}


</style>

<script>

import Store from "@/store.js"

import MainBar from "@/components/MainBar.vue"
import NavDrawer from "@/components/NavDrawer.vue"
import Messages from "@/components/Messages.vue"
import NavBottom from "@/components/NavBottom.vue"
import Share from "@/components/Share.vue"
import ModalDialog from "@/components/ModalDialog.vue"

export default {
	components: { MainBar, NavDrawer, Messages, NavBottom, Share, ModalDialog },

	watch: {

		'Store.user'(newValue) {
			if (newValue === null) {
				this.$router.push("/");
			}
		},

		'Store.notfound'(newValue) {
			if (newValue === false) return;
			Store.pushMessage("Si è verificato un errore del server", "error");
			Store.notfound = false;
		},
	},

	async mounted() {
		if (Store.user) {
			let res = await Store.fetch("/helo");
			Store.notices = res.notices;
		}
	},


}

</script>
