<template>
<Spinner v-if="init"/>
<section v-else>

	<div v-if="!Store.user" class="card">
		<p class="icon">
			Registrati a Trallallibro per creare la tua libreria e rispondere agli annunci
		</p>
		<p class="flex-b">
			<router-link class="button mdi mdi-login" to="/login">Accedi</router-link>
			<router-link class="submit mdi mdi-pencil-box-outline" to="/register">Registrati</router-link>
		</p>
	</div>

		<div v-if="!Store.options.home_about" class="card">
		<p class="icon">
			Prima volta su Trallallibro? <router-link to="/about">Scopri il servizio!</router-link>
		</p>
		<p class="flex-b">
			<a class="button mdi mdi-close" @click="Store.setOption('home_about', true)">Chiudi</a>
		</p>
	</div>

	<div v-if="!Store.options.home_share" class="card">
		<p class="icon">
			Ti piace Trallallibro? Fallo conoscere ai tuoi amici!
		</p>
		<p class="flex-b">
			<a class="button mdi mdi-close" @click="Store.setOption('home_share', true)">Nascondi</a>
			<a class="submit mdi mdi-share-variant" @click="share">Condividi</a>
		</p>
	</div>

	<div class="card">
		<p>
			<a class="flex-h" @click="overlay_flag=true">
				<span class="greedy">
					Scegli il tipo di annunci e il genere di libri
				</span>
				<i class="mdi mdi-dots-vertical"></i>
			</a>
		</p>
	</div>

	<BookList v-if="list && list.length" :list="list"/>
	<p v-else class="text-center">
		<i class="mdi mdi-emoticon-sad-outline mdi-big"></i>
		<br/>
		Non ci sono annunci nei tuoi dintorni
	</p>

	<router-link v-if="Store.user" class="floating-button" to="/advert/pick" title="Pubblica un annuncio">
		<i class="mdi mdi-plus"></i>
		<i class="mdi mdi-bullhorn"></i>
	</router-link>

	<article v-show="overlay_flag" class="overlay" @click.self="overlay_flag=false">
		<div class="card home-filter-form">
			<h2>Filtra gli annunci</h2>
			<form>
				<h6>Tipologie di annunci</h6>
				<div>
					<label @click="filters.donate = filters.swap = filters.lend = filters.sell = true">
						<i class="mdi" :class="((filters.donate && filters.swap && filters.lend && filters.sell) ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>Tutte le tipologie
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.donate" hidden>
						<i class="mdi" :class="(filters.donate ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-gift"></i>Regali
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.swap" hidden>
						<i class="mdi" :class="(filters.swap ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-swap-horizontal"></i>Scambi
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.lend" hidden>
						<i class="mdi" :class="(filters.lend ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-alarm"></i>Prestiti
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.sell" hidden>
						<i class="mdi" :class="(filters.sell ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-shopping"></i>Vendite
					</label>
				</div>
				<hr class="wrap"/>
				<h6>Generi letterari</h6>
				<div>
					<label @click="filters.noir = filters.romance = filters.fantasy = filters.kids = filters.comic = filters.essay = filters.poetry = true">
						<i class="mdi" :class="((filters.noir && filters.romance && filters.fantasy && filters.kids && filters.comic && filters.essay && filters.poetry) ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>Tutti i generi
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.noir" hidden>
						<i class="mdi" :class="(filters.noir ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-pistol"></i>Noir, Thriller, Gialli
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.romance" hidden>
						<i class="mdi" :class="(filters.romance ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-heart"></i>Romanticismo
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.fantasy" hidden>
						<i class="mdi" :class="(filters.fantasy ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-sword"></i>Fantasy, Fantascienza, Avventura
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.kids" hidden>
						<i class="mdi" :class="(filters.kids ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-teddy-bear"></i>Ragazzi
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.comic" hidden>
						<i class="mdi" :class="(filters.comic ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-gift"></i>Fumetti e umorismo
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.essay" hidden>
						<i class="mdi" :class="(filters.essay ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-school"></i>Saggi
					</label>
				</div>
				<div>
					<label>
						<input type="checkbox" v-model="filters.poetry" hidden>
						<i class="mdi" :class="(filters.poetry ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')"></i>
						<i class="mdi mdi-music"></i>Poesia
					</label>
				</div>
				<p class="flex-c">
					<a class="submit mdi mdi-check" @click="overlay_flag=false">Applica</a>
				</p>
			</form>
		</div>
	</article>
</section>
</template>

<style lang="scss">
.home-filter-form {
	label {
		cursor: pointer;
	}
	i {
		padding-right: .5rem;
	}
}
</style>

<script>

import Store from "@/store.js"

import Spinner from "@/components/Spinner.vue"
import BookList from "@/components/BookList.vue"

export default {

	components: { Spinner, BookList },

	data: () => ({
		Store,
		init: true,
		list: [],
		overlay_flag: false,
		filters: {
			donate: true,
			swap: true,
			lend: true,
			sell: true,

			noir: true,
			fantasy: true,
			kids: true,
			comic: true,
			essay: true,
			poetry: true,
		},
	}),

	async mounted() {
		Store.setView("Trallallibro");

		let f = [];
		for (let i in this.filters) if (this.filters[i]) f.push(i);

		let res = await Store.fetch("/home", { tags: f.join(",") });
		Store.notices = res.notices;

		this.list = res.adverts.map(a => Store.bookFactory(a));
		this.init = false;
	},

	methods: {
		async share() {

			Store.trackEvent('share');

			if (navigator.share) {
				navigator.share({
					title: Store.name,
					text: "Condividi, scambia, regala e vendi libri",
					url: "https://app.trallallibro.com/",
				});
			} else {
				Store.setShare(Store.name);
			}
		}
	},
}
</script>
