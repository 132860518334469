<template>
<nav id="menu" :class="{ active: Store.menu }" @click="Store.hideMenu()">
	<div>
		<div id="logo"><img src="/logo.svg" alt="Trallallibro">Trallallibro</div>
		<hr/>
		<router-link to="/" class="mdi mdi-map-marker-radius">Annunci intorno a te</router-link>
		<router-link to="/search" class="mdi mdi-magnify">Cerca un annuncio</router-link>
		<hr/>
		<div v-if="Store.user">
			<router-link to="/notices" class="mdi mdi-bell">Notifiche</router-link>
			<router-link to="/book" class="mdi mdi-book-open-variant">La tua Libreria</router-link>
			<router-link to="/adverts" class="mdi mdi-bullhorn">I tuoi Annunci</router-link>
			<router-link to="/profile" class="mdi mdi-account-circle">{{ Store.user.name }}</router-link>
			<a @click="logout" class="mdi mdi-logout-variant">Esci</a>
		</div>
		<div v-else>
			<router-link to="/register" class="mdi mdi-pencil-box-outline">Registrati</router-link>
			<router-link to="/login" class="mdi mdi-login-variant">Accedi</router-link>
		</div>
		<hr/>
		<a class="mdi mdi-share-variant" @click="share">Condividi Trallallibro</a>
		<router-link to="/help" class="mdi mdi-help-circle">Guida rapida</router-link>
		<router-link to="/about" class="mdi mdi-information">Chi siamo</router-link>
		<router-link to="/termini-e-condizioni" class="mdi mdi-check-circle">Regolamento</router-link>
		<router-link to="/privacy" class="mdi mdi-lock">Privacy</router-link>
		<a href="https://www.facebook.com/app.trallallibro/" target="_system" class="mdi mdi-bug">Segnala un problema <i class="mdi mdi-open-in-new"></i></a>
		<hr/>
		<a href="https://www.facebook.com/app.trallallibro/" target="_system" class="mdi mdi-facebook">Facebook</a>
		<a href="https://www.instagram.com/trallallibro/" target="_system" class="mdi mdi-instagram">Instagram</a>
	</div>
</nav>
</template>

<style lang="scss">
#menu {
	> div {
		position: fixed;
		width: 18rem;
		background: white;
		top: 0;
		left: -18rem;
		height: 100%;
		box-sizing: border-box;
		overflow: auto;
		transition: left .3s;
		box-shadow: var(--shadow-2);
		z-index: 11;
		padding: 1rem 0;
	}

	#logo {
		font-size: 3rem;
		font-family: ReenieBeanie;
	}

	img {
		width: 4rem;
		vertical-align: middle;
	}

	a {
		display: block;
		padding: .5rem;
		&:hover {
			color: white;
			background: var(--color-vibrant);
		}
	}

	&::before {
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		visibility: hidden;
		z-index: 10;
		opacity: 0;
		transition: opacity .3s, visibility 0s linear 0.5s;
	}

	&.active {
		> div {
			left: 0;
		}
		&::before {
			visibility: visible;
			opacity: .6;
			transition-delay: 0s;
		}
	}

}
</style>

<script>

import Store from "@/store.js";

export default {
	data: () => ({
		Store,
	}),

	methods: {
		logout() {
			Store.unsetUser();
			this.$router.push("/");
		},

		async share() {
			Store.trackEvent('share');
			if (navigator.share) {
				navigator.share({
					title: Store.name,
					text: "Condividi, scambia, regala e vendi libri",
					url: "https://app.trallallibro.com/",
				});
			} else {
				Store.setShare(Store.name);
			}
		}
	},
}

</script>
