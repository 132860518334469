<template>
	<router-link v-if="user" class="user-chip" :to="user.url">
		<img :src="user.avatar_url"/>
		<span>
			{{ user.name }}
			<i class="mdi mdi-star"></i>
			{{ user.feedback }}
		</span>
	</router-link>
</template>

<style lang="scss">
.user-chip {
	background: var(--color-primary);
	color: white;
	line-height: 2rem;
	display: inline-block;
	border-radius: 1rem;
	overflow: hidden;
	box-shadow: var(--shadow-1);

	&:hover {
		color: white;
		background: var(--color-vibrant);
		box-shadow: var(--shadow-2);
	}

	span {
		padding: 0 1rem 0 .5rem;
	}

	img {
		float: left;
		width: 2rem;
		height: 2rem;
		border-radius: 1rem;
	}
}
</style>

<script>

export default {
	props: ["user"],
}

</script>
