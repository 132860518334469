<template>
<aside id="messages">
	<div v-for="(v, k) in Store.messages" :key="v" :class="'messages-'+v.type">
		<span>{{ v.text }}</span>
		<a @click="Store.removeMessage(k)" class="mdi mdi-close"></a>
	</div>
</aside>
</template>

<style lang="scss">
#messages {
	position: fixed;
	bottom: 4rem;
	width: calc(100% - 2rem);
	margin: 0 1rem;
	div {
		display: flex;
		margin: 1rem 0;
		padding: 1rem;
		border-radius: .25rem;
		box-shadow: var(--shadow-2);
		&.messages-success {
			background: #dfd;
		}
		&.messages-warning {
			background: #ffd;
		}
		&.messages-error {
			background: #fdd;
		}
		span {
			flex: auto;
		}
	}
}
</style>

<script>

import Store from "@/store.js";

export default {
	data: () => ({
		Store,
	}),
}

</script>
