<template>
<footer>
	<div>
		<router-link to="/">
			<i class="mdi mdi-map-marker-radius"></i>
			<br/>
			Trova
		</router-link>
		<router-link v-if="Store.user" to="/book">
			<i class="mdi mdi-book-open-variant"></i>
			<br/>
			Libreria
		</router-link>
		<router-link v-if="Store.user" to="/adverts">
			<i class="mdi mdi-bullhorn"></i>
			<br/>
			Annunci
		</router-link>
		<router-link v-if="Store.user" to="/notices" :class="{ alert: Store.notices }">
			<i class="mdi mdi-bell"></i>
			<br/>
			Notifiche
		</router-link>
		<router-link v-if="!Store.user" to="/login">
			<i class="mdi mdi-login-variant"></i>
			<br/>
			Accedi
		</router-link>
		<router-link v-if="!Store.user" to="/register">
			<i class="mdi mdi-pencil-box-outline"></i>
			<br/>
			Registrati
		</router-link>
	</div>
</footer>
</template>

<style lang="scss">
footer {
	background: white;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	box-shadow: var(--shadow-2);

	> div {
		max-width: 32rem;
		margin: auto;
		height: 4rem;
		display: flex;
	}

	a {
		flex: auto;
		text-align: center;
		padding: .5rem;
		position: relative;
		box-sizing: border-box;
		border-style: solid;
		border-color: transparent;
		border-width: 3px 0 0 0;

		&:hover {
			background: var(--color-vibrant);
			color: white;
		}

		&.router-link-active {
			border-color: var(--color-primary);
		}

		&.alert::after {
			content: "";
			background: red;
			width: .5rem;
			height: .5rem;
			display: block;
			border-radius: .25rem;
			position: absolute;
			top: .5rem;
			left: calc(50% + .5rem);
		}
	}
}
</style>

<script>

import Store from "@/store.js";

export default {
	data: () => ({
		Store,
	}),
}

</script>
