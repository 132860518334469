import { createRouter, createWebHistory } from "vue-router"
import Home from "@/views/Home.vue"

const routes = [{
		path: "/",
		name: "Home",
		component: Home
	}, {
		path: "/about",
		component: () => import("@/views/About.vue")
	}, {
		path: "/help",
		component: () => import("@/views/Help.vue")
	}, {
		path: "/privacy",
		component: () => import("@/views/Privacy.vue")
	}, {
		path: "/termini-e-condizioni",
		component: () => import("@/views/Terms.vue")
	}, {
		path: "/search",
		component: () => import("@/views/Search.vue")
	}, {
		path: "/book",
		component: () => import("@/views/Library.vue")
	}, {
		path: "/book/add",
		component: () => import("@/views/BookAdd.vue"),
	}, {
		path: "/book/add/search",
		component: () => import("@/views/BookAddSearch.vue"),
	}, {
		path: "/book/:id_book",
		component: () => import("@/views/Book.vue"),
		children: [
			{
				path: "",
				component: () => import("@/views/Book/Info.vue")
			}, {
				path: "advert/:id_advert",
				component: () => import("@/views/Book/Advert.vue")
			}, {
				path: "history",
				component: () => import("@/views/Book/History.vue")
			}, {
				path: "adverts",
				component: () => import("@/views/Book/Adverts.vue")
			}
		],
	}, {
		path: "/book/:id_book/advert/:id_advert/swap",
		component: () => import("@/views/AdvertSwap.vue")
	}, {
		path: "/adverts",
		component: () => import("@/views/Adverts.vue")
	}, {
		path: "/profile",
		component: () => import("@/views/Profile.vue")
	}, {
		path: "/profile/email",
		component: () => import("@/views/ProfileEmail.vue")
	}, {
		path: "/profile/password",
		component: () => import("@/views/ProfilePassword.vue")
	}, {
		path: "/profile/delete",
		component: () => import("@/views/ProfileDelete.vue")
	}, {
		path: "/profile/:id_user",
		component: () => import("@/views/User.vue"),
		children: [
			{
				path: "",
				component: () => import("@/views/User/Info.vue"),
			}, {
				path: "books",
				component: () => import("@/views/User/Books.vue"),
			}, {
				path: "adverts",
				component: () => import("@/views/User/Adverts.vue"),
			}, {
				path: "feedback",
				component: () => import("@/views/User/Feedback.vue"),
			}
		],
	}, {
		path: "/advert/pick",
		component: () => import("@/views/AdvertPick.vue")
	}, {
		path: "/advert/:id",
		component: () => import("@/views/Book/Advert.vue")
	}, {
		path: "/book/:id_book/advert/:id_advert/report",
		component: () => import("@/views/AdvertReport.vue")
	}, {
		path: "/advert/new",
		component: () => import("@/views/AdvertEdit.vue")
	}, {
		path: "/advert/:id_advert/edit",
		component: () => import("@/views/AdvertEdit.vue")
	}, {
		path: "/advert/:id_advert/queue",
		component: () => import("@/views/AdvertQueue.vue")
	}, {
		path: "/advert/:id_advert/feedback/:id_user_rel",
		component: () => import("@/views/Feedback.vue")
	}, {
		path: "/login",
		component: () => import("@/views/Login.vue")
	}, {
		path: "/recovery",
		component: () => import("@/views/Recovery.vue")
	}, {
		path: "/register",
		component: () => import("@/views/Register.vue")
	}, {
		path: "/logout",
		component: () => import("@/views/Login.vue")
	}, {
		path: "/notices",
		component: () => import("@/views/Notices.vue")
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
